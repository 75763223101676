<div class="nav-content-container flex flex-col md2:flex-row flex-evenly-stretch md2:flex-start-stretch">
  @if(navContent$ | async; as navContent) {
    <nav class="nav-content-items-container flex flex-col sm:flex-row sm:flex-wrap flex-start-stretch sm:flex-start-start sm:gap-12 flex-1">
      @for(item of navContent[0]; track item.link) {
        <navigation-content-item [navItem]="item"
                                 [active]="item.link === navContent[1]"
                                 (selected$)="navSelected()"></navigation-content-item>
      }
    </nav>
  }
  <div class="nav-divider-container flex max-md2:flex-col flex-start-stretch md2:flex-center-center">
    <mat-divider [vertical]="isVertical$ | async"></mat-divider>
  </div>
  <div class="nav-social-links-container flex flex-center-center flex-initial">
    <navigation-social-links></navigation-social-links>
  </div>
</div>
